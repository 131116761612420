<template>
  <div class="_bg-default _80w _100vh">
    <div class="_100top py-3">
      <h3 class="text-center">Cari Psikolog di :</h3>
      <svg-map />
      <!-- <MapPshycolog v-bind:role="role" /> -->
    </div>
  </div>
</template>

<script>
import SvgMap from "../map/svgMap.vue";
// import MapPshycolog from "../General/mapPshycolog.vue";
export default {
  name: "homePsy",
  components: {
    SvgMap,
    // MapPshycolog
  },
  data() {
    return {
      role: true,
    };
  },
  mounted() {
    this.$store.dispatch("counseling/chekRequest");
  },
};
</script>

<style scoped>
</style>
